@font-face {
    font-family: 'Viamaax';
    src: url('../fonts/viamaax-webfont.woff2') format("woff2"),
         url('../fonts/viamaax-webfont.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Viamaaxbold";
    src: url('../fonts/viamaax-bold-webfont.woff2') format("woff2"),
         url('../fonts/viamaax-bold-webfont.woff') format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Viamaaxitalic";
    src: url('../fonts/viamaax-italic-webfont.woff2') format("woff2"),
         url('../fonts/viamaax-italic-webfont.woff') format("woff");
    font-weight: normal;
    font-style: italic;
}
