
.detail-banner {
    background-position: center center;
    height: 325px;
    position: relative;
    margin-bottom: 40px;

    @media (min-width: $screen-tablet) {
        height: 600px;
        margin-bottom: 80px;
    }

    &.community {
      background-image: url('../img/community-small.jpg');

      @media (min-width: $screen-tablet) {
          background-image: url('../img/community-big.jpg');
      }
    }

    &.construction-site {
      background-image: url('../img/construction-small.jpg');
      background-position: left center;

      @media (min-width: $screen-tablet) {
          background-image: url('../img/construction-big.jpg');
          background-position: center center;
      }
    }

    &.upcoming-event {
      background-image: url('../img/upcoming-event-small.jpg');

      @media (min-width: $screen-tablet) {
          background-image: url('../img//upcoming-event-big.jpg');
      }
    }

    &:before {
        background: -webkit-linear-gradient(to right, rgba(0,0,0,.3) -10%, rgba(0,0,0,.5) 20%, rgba(0,0,0,.5) 40%, rgba(0,0,0,0) 80%);
        background: -moz-linear-gradient(to right, rgba(0,0,0,.3) -10%, rgba(0,0,0,.5) 20%, rgba(0,0,0,.5) 40%, rgba(0,0,0,0) 80%);
        background: linear-gradient(to right, rgba(0,0,0,.3) -10%, rgba(0,0,0,.5) 20%, rgba(0,0,0,.5) 40%, rgba(0,0,0,0) 80%);
        bottom: 0px;
        content: '';
        left: 0px;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
    }

    .container {
        height: 100%;
        position: relative;
    }

    .detail-banner-left {
        position: absolute;
        top: 50%;
        left: 15px;
        right: 15px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        z-index: 1;
    }

    h1 {
        color: #fff;
        margin-bottom: 10px;
        text-shadow: 0px 0px 20px #000;
        text-transform: uppercase;
    }
}

.detail-content {
  padding-bottom: 20px;

    h2 {
        border-bottom: 1px solid $color-gray-medium;
        color: $color-black-light;
        font-size: 24px;
        font-weight: 300;
        margin: 0 0 30px 0;
        padding-bottom: 20px;
    }

    aside h2 {
      @media (max-width: $screen-xs-max) {
        margin-top: 40px;
      }
    }

    #listing-detail-location {
        border-bottom: none;

        a {
            background-color: $color-gray;
            border: none;
            border-radius: 0px;
            color: $color-gray-darker;
            font-size: 13px;
            margin-bottom: 4px;
            margin-right: 3px;
            transition: all linear .12s;

            &:hover {
                background-color: darken($color-gray, 5%);
            }
        }

        li.active a {
            background-color: $color-gray-darker;
            color: #fff;

            &:hover {
                background-color: darken($color-gray-darker, 5%);
            }
        }

        i {
            display: inline-block;
            margin-right: 10px;

            &.fa-map-o, &.fa-map {
                font-size: 10px;
                vertical-align: 1px;
            }

            &.fa-street-view {
                font-size: 12px;
            }
        }
    }

    #listing-detail-map, #listing-detail-street-view {
        height: 300px;

        img {
            height:auto !important;
        }
    }

    .detail-actions {
        &.row {
            margin-left: -10px;
            margin-right: -10px;
        }

        .col-sm-4 {
            padding-left: 10px;
            padding-right: 10px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        .btn {
            width: 100%;
        }

        i {
            margin-right: 4px;
        }
    }

    .detail-vcard {
        border-bottom: 1px solid $color-gray-medium;
        display: table;
        margin-bottom: 20px;
        padding-bottom: 20px;
        width: 100%;
    }

    .detail-contact-website{
        display: flex;

        ul {
          list-style: none;
          padding-left: 5px;
        }
    }

    .detail-logo {
        display: table-cell;
        padding-right: 20px;
        text-align: center;
        vertical-align: middle;
        width: 40%;

        img {
            max-width: 100%;
        }
    }

    .detail-contact {
        @media (min-width: $screen-tablet) {
          display: flex;
          justify-content: space-around;
        }

        & > * {
          margin: 2px 0;
        }

        .detail-contact-address {
          @media (min-width: $screen-tablet) {
            max-width: 40%;
          }
        }

        .icon {
          height: 16px;
          width: 16px;
        }

        a {
            color: $color-lochmara;
            transition: all 0.15s linear;

            &:hover, &:focus {
              color: $color-lochmara;
              text-decoration: underline;
            }
        }
    }

    .detail-description {
        border-bottom: 1px solid $color-gray-medium;
        margin-bottom: 20px;
        padding-bottom: 20px;

        ul {
          list-style-position: inside;
          padding: 0;
        }

        p > a {
          color: $color-lochmara;
        }
    }
}

.detail-verified {
  background-color: $color-supernova;
  color: $color-black-light;
  display: none;
  font-size: 16px;
  padding: 3px 15px;
  text-align: center;
  margin-top: 10px;
  margin-right: 10px;

  @media (min-width: $screen-tablet) {
    display: inline-block;
  }
}

.detail-image {
  margin-bottom: 40px;
  img {
    width: 100%;
  }
}

.detail-map .map-position img {
  max-width: 100%;
}
