.pager {
    margin: 60px 0px 0px 0px;

    ul {
        margin: 0px;
        padding: 0px;
    }

    li {
        margin: 0px 4px;

        &.page {
          @media (max-width: $screen-tablet) {
            display: none;
          }
        }

        a {
            border-color: $color-gray;
            border-radius: 0;
            color: $color-black-light;
            padding: 6px 15px;
            transition: all .15s linear;

            &:hover, &:focus {
                background-color: $color-black-light;
                color: #fff;
            }

            &.next, &.previous {
              background-color: $color-gray;

              &:hover, &:focus {
                  background-color: $color-black-light;
                  color: #fff;
              }
            }
        }

        &.active {
            a {
                background-color: $color-supernova;
                border-color: $color-supernova;

                &:hover, &:focus {
                    background-color: $color-black-light;
                    border-color: $color-black-light;
                    color: $color-supernova;
                }
            }
        }
    }
}
