
.cards-simple-wrapper {
    margin-bottom: -30px;

    h2 {
      font-size: 24px;
      font-weight: bold;

      @media (min-width: $screen-tablet) {
        font-size: 40px;
      }
    }
}

.card-simple {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-backface-visiblity: hidden;
    margin-bottom: 30px;
    overflow: hidden;
    -webkit-transform: translateZ(0px);

    &:hover,
    &:focus {
        .card-simple-background:before {
            opacity: 1;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            -webkit-backface-visiblity: hidden;
        }

        .card-simple-label {
            margin-top: -25px;
        }

        .card-simple-content {
            padding-top: 0px;
            transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            -webkit-backface-visiblity: hidden;
        }

        .card-simple-rating {
            opacity: 1;
            transition-delay: .05s;
            -webkit-backface-visiblity: hidden;
        }

        .card-simple-actions {
            opacity: 1;
            transition-delay: .1s;
            -webkit-backface-visiblity: hidden;
        }
    }
}

.card-simple-background {
    height: 200px;
    position: relative;

    img {
        width: 100%;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba($color-black, 0) 0%, rgba($color-black, 0.7) 75%);
        content: '';
        opacity: 0;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        transition: all ease .35s;
    }

    &:after {
        background-color: rgba(black, .3);
        bottom: 0px;
        content: '';
        display: block;
        left: 0px;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
    }
}

.card-simple-label {
    background-color: $color-supernova;
    color: black;
    left: 50%;
    font-size: 13px;
    padding: 3px 15px;
    position: absolute;
    top: 0px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transition: all ease .35s;
    z-index: 2;

    a {
        color: #fff;

        &:hover,
        &:focus {
            color: #fff;
            text-decoration: none;
        }
    }
}

.card-simple-content {
    left: 50%;
    padding-top: 85px; //30px + 55px (rating + actions height)
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all ease .35s;
    width: 100%;
    z-index: 2;

    h3 {
        color: #fff;
        display: block;
        font-size: 20px;
        margin: 0px;
        overflow: hidden;
        padding: 0px 20px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
            color: #fff;
            text-shadow: 0px 1px 2px rgba(0,0,0,0.1);

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.card-simple-rating {
    color: #fff;
    margin-top: 10px;
    opacity: 0;
    font-size: 15px;
    text-align: center;
    transition: all ease .35s;
}

.card-simple-actions {
    color: #fff;
    margin-top: 20px;
    opacity: 0;
    text-align: center;
    transition: all ease .35s;

    .fa {
        border: 1px solid #fff;
        border-radius: 50%;
        color: #fff;
        height: 32px;
        margin: 0px 6px;
        padding-top: 8px;
        transition: all ease .2s;
        width: 32px;

        &:hover {
            background-color: #fff;
            color: $color-black;
        }
    }

    .fa-search {
        font-size: 18px;
        height: 40px;
        padding-top: 9px;
        width: 40px;
    }
}

.cards-row {
  list-style-type: none;
  padding: 0;
}

.card-row {
    background-color: #fff;
    margin: 0px 0px 30px 0px;

    &:last-child {
        margin-bottom: 0px;
    }
}

.card-row-inner {
    display: table;
    width: 100%;
}

.card-row-image, .card-row-body, .card-row-properties {
    display: table-cell;
    vertical-align: top;

    @media (max-width: $screen-md-max) {
        display: block;
    }
}

.card-row-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    position: relative;
    width: 262px;

    @media (max-width: $screen-md-max) {
        width: 100%;
    }
}

.card-row-body {
    padding: 30px;
}

.card-row-properties {
    padding: 30px 30px 30px 30px;
    width: 330px;

    @media (max-width: $screen-md-max) {
        padding: 0px 30px 30px 30px;
        width: 100%;
    }
}

.card-row-title {
    font-size: 19px;
    margin: 0px 0px 20px 0px;
    padding: 0px;

    @media (min-width: $screen-tablet) {
      font-size: 24px;
    }

    a {
        color: $color-black-light;

        &:hover {
          color: $color-black-light;
        }
    }
}

.card-row-properties {

    ul {
       list-style: none;
    }

    li{
        margin-bottom:8px;
    }

    dl {
        margin: 0px;
        padding: 0px;
    }

    dt {
        float: left;
        line-height: 18px;
        padding: 9px 0;
    }

    dd {
        border-bottom: 1px dashed $color-gray-medium;
        line-height: 18px;
        padding: 9px 0;
        text-align: right;

        &:last-child {
            border-bottom: 0px;
        }
    }
}

.card-row-label {
    background-color: $color-supernova;
    color: black;
    left: 50%;
    font-size: 13px;
    padding: 3px 15px;
    position: absolute;
    top: 0px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    z-index: 2;

    a {
        color: inherit;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }
}
