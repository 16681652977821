.btn {
    border: 0px;
    border-radius: 0px;
    font-size: 16px;
    line-height: 22px;
    padding: 9px 15px;
    position: relative;
    transition: background-color .15s linear;
    white-space: normal;

    @media (min-width: $screen-tablet) {
      white-space: nowrap;
    }
}

.btn-subscribe {
    margin: 15px !important;
    padding: 12px 60px!important;
    min-height: fit-content !important;
}

.btn-primary {
    background-color: $color-supernova;
    color: $color-black-light;
    display: inline-block;
    font-weight: bold;
    min-height: 48px;

    &:active, &:focus, &:hover {
        background-color: $color-black-light;
        color: $color-supernova;
    }
}

.btn-secondary {
    background-color: $color-secondary;
    color: #fff;
    display: inline-block;
    padding: 9px 15px;
    transition: background-color .15s linear;

    &:active, &:focus, &:hover {
        background-color: darken($color-secondary, 5%) !important;
        box-shadow: none !important;
        color: #fff;
        outline: 0px !important;
        text-decoration: none;
    }
}

.btn-transparent {
    background-color: rgba(black, .4);
    color: #fff;

    &:hover, &:focus {
        background-color: rgba(black, .5);
        color: #fff;
    }

    .fa {
        font-size: 11px;
        margin-left: 9px;
    }
}

.btn-lg {
    padding: 16px 30px;
}

.btn-xs {
    padding: 6px 12px;
}
