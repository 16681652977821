.filter {
    background-color: #fff;
    margin: 0px 0px 20px 0px;
    padding: 20px;

    @media (min-width: $screen-tablet) {
      margin: 0px 0px 40px 0px;
    }

    .checkbox-wrapper {
        @extend .clearfix;
        margin-bottom: 10px;

        .checkbox {
            @extend .col-sm-6, .col-md-4, .col-lg-3;

            margin-bottom: 7px;
            margin-top: 7px;
            padding: 0px;

            label {
                color: $color-gray-darker;
                font-weight: 400;
                padding: 0px;
            }
        }
    }

    .form-group {
        margin-bottom: 0px;
    }

    button[type=submit] {
        float: right;

        @media (max-width: $screen-xs-max) {
            float: none;
            margin: 30px 0px 0px 0px;
        }
    }
}

.filter-actions {
    margin-top: 10px;

    a {
        color: $color-lochmara;
        font-weight: 400;
        margin: 0px 30px 0px 0px;

        &:hover {
            color: $color-lochmara;
            text-decoration: underline;
        }
    }

    .fa {
        color: black;
        font-size: 13px;
        margin: 0px 7px 0px 0px;
        vertical-align: 0px;
    }
}

.filter-sort {
    margin: 0;
    padding: 0;

    .form-group {
        display: inline-block;
    }
}

.number-of-results {
  @media (max-width: $screen-xs-max) {
    font-size: 18px;
  }
}
