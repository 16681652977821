label {
  color: $color-black-light;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
}


.form-control {
  box-shadow: none;
  border-radius: 0px;
  border: 2px solid $color-gray-medium;
  //min-height: 48px;
  height: auto;
  line-height: 20px;

  &:active, &:focus {
    border: 2px solid $color-gray-medium;
    box-shadow: none;
    outline: 0px;
  }
}

.form-horizontal .control-label {
  padding-top: 12px;
}

.form-horizontal .checkbox {
  margin-top: 7px;
  padding-top: 0px;
}

.form-inline {
  .form-group {
    margin-right: 30px;

    label {
      margin-right: 8px;
    }

    input[type=checkbox] + label, input[type=radio] + label {
      display: inline-block;
    }
  }

  h6 {
    display: inline;
    margin-right: 8px;
  }
}

textarea {
  resize: vertical;
}

button {
  i {
    margin-right: 10px;
  }
}

input[type="text"] {
  background-color: #fff;
  border: 1px solid  $color-mercury;
  color: #767676;
  min-height: 48px;
  padding: 10px 15px;

  &::placeholder{
    color: $color-mercury;
  }
}


.bootstrap-select {
  width: 100% !important;

  .dropdown-toggle {
    padding-right: 27px;

    .filter-option {
      margin-top: 3px;
    }
  }

  .dropdown-toggle,
  .dropdown-toggle.bs-placeholder {
    background-color: #fff !important;
    border: 1px solid $color-mercury !important;
    color: $color-boulder !important;
    display: flex;
    min-height: 48px;

    &:after {
      display: none;
    }

    &:active, &:focus {
      background-color: $color-black-light !important;
      border: 1px solid $color-black-light !important;
      box-shadow: none !important;
      color: $color-supernova !important;
      outline: 0px !important;
    }

    .caret {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px dashed;
      color: black;
    }
  }

  &:active, &:focus {
    border: 0px !important;
    box-shadow: none !important;
    outline: 0px !important;
  }

  &.open {
    box-shadow: none !important;
    outline: 0px !important;

    .dropdown-toggle {
      background-color: $color-black-light !important;
      border-color: $color-black-light !important;
      box-shadow: none !important;
      outline: 0px !important;
      transition: none;

      & > .filter-option {
        color: $color-supernova;
      }
    }

    & > div.dropdown-menu.open {
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0;

      & > ul > li > a {
        color: white;
        font-size: 16px;
        height: 48px;

        &:hover,
        &:focus {
          background-color: $color-black;
          color: $color-supernova;
        }

        .text {
          line-height: 40px;
        }
      }

      & > * {
        background-color: $color-black-light !important;
      }
    }
  }
}
