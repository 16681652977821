
.cc__bold {
  font-weight: 700
}

.cookies__container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  width: 100%;
  @media only screen and (min-width: 900px) {
    width: 1036px;
  }
}

.cc-dismiss-close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2.5rem;
  color: #ffffff;
  margin: 0;
  text-decoration: none;
  line-height: 1.1;
  height: 30px;
}

.cc-window {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999;
  font-family: Lato;
  font-size: 1.6rem;
  line-height: 1.3;
  letter-spacing: 0;
  background-color: #1b1b1b !important;
  color: #ffffff
}

.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em;
  -ms-flex-direction: column;
  flex-direction: column
}

.cc-top {
  top: 1em
}

.cc-left {
  left: 1em
}

.cc-right {
  right: 1em
}

.cc-bottom {
  bottom: 1em
}

.cc-floating .cc-message {
  font-family: Viamaax;
  display: block;
  color: #ffffff;
  padding: 16px 0;
}

@media print {
  .cc-revoke,
  .cc-window {
    display: none
  }
}

@media screen and (max-width:900px) {
  .cc-btn {
    white-space: normal
  }
}

@media screen and (max-width:414px) and (orientation:portrait),
screen and (max-width:736px) and (orientation:landscape) {
  .cc-window.cc-top {
    top: 0
  }
  .cc-window.cc-bottom {
    bottom: 0
  }
  .cc-window.cc-left,
  .cc-window.cc-right {
    left: 0;
    right: 0
  }
  .cc-window.cc-floating {
    max-width: none
  }
}

.cc-floating.cc-theme-classic {
  display: none;
  padding: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
}

.cc-dismiss {
  text-align: right;
  padding-top: 1em;
}

.cc-arrow {
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-top: 10px;
}

.closed-cookie-alert .cc-message {
  display: none
}

.opened-cookie-alert .cc-arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg)
}

.closed-cookie-alert .cc-arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg)
}

.closed-cookie-alert {
  display: none !important;
}

.cc-bottom.closed-cookie-alert {
  bottom: 0 !important
}

.icons8-cancel {
  path {
    fill: white;
  }
}

