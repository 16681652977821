
.subscription {

  .hero-image {
    background-image: url("../img/RS117_VIA_Landscape_RS4533_Via_Part_2_36_V2_sRGB.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .container {
    background-color: white;
    padding: 30px;
    margin-top: 30px;
    @media (max-width: $screen-tablet) {
      margin-top: 0;
    }
  }

  .section {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: $screen-tablet) {
      width: 100%;
    }

    &.regions {
      width: 100%;
    }
  }

  .label-bold {
    color: $color-black-light;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
  }
  label {
    &.required {
      &::after {
        color: #f00;
        content: "*";
        margin-left: 5px;
      }
    }
  }

  .error {
    li {
      color: $color-red !important;
    }
    input {
      border: 2px solid #C42026;
    }

    .subscription_regions {
      border: 2px solid #C42026;
    }
  }

  .confirmation-body {
    border-top: 2px solid $color-black-light;
    padding-top: 30px;
  }

  .title {
    @media (max-width: $screen-tablet) {
      text-align: center;
    }
  }

  #subscription_save {

    @media (min-width: $screen-tablet + 1) {
      width: 150px;
    }

  }

  .list-errors {
    background: #fcc;
    color: #200;
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid #d77;

    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;

      li {
        line-height: 1.7;
        margin: 0 20px 5px 20px;

      }

      a {
        color: #333;
        text-decoration: underline;
        display: inline-block;
        font-size: 13px;
        line-height: 1.37;
        margin-bottom: 5px;
      }
    }
  }
}