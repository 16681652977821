.button_radiobox {
  @media (min-width: $screen-tablet + 1) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.radio-box-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: left;
  padding: 0;
  background-color: white;
  padding-left: 40px;
  flex-basis: 33%;
  max-width: 33%;

  &:hover, &:focus, &:active, &.focus {
    background-color: white;
    input ~ .checkmark {
      background-color: #ccc;
      @media (min-width: $screen-tablet + 1) {
        box-shadow: 0 0 0 1px #4d90fe;
      }
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 25px;
  }

}

/* Hide the browser's default checkbox */
.radio-box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}



.radio-box-container input:checked ~ .checkmark {
  background-color: #ffcb06;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;

}

/* Show the checkmark when checked */
.radio-box-container input:checked ~ .checkmark:after {
  display: block;
  box-sizing: content-box;
}

/* Style the checkmark/indicator */
.radio-box-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #1b1b1b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}