/*------------------------------------------------------------------

[Table of contents]

1. Fonts
2. Bootstrap Library
3. Variables
4. Buttons
5. Common classes
6. Forms
7. Header
8. Footer
9. Pagination
10. Map
11. Homepage Image
12. Social Buttons
13. Listing Cards
14. Filter
15. Listing Detail
*/

$icon-font-path: '../libraries/bootstrap-sass/fonts/bootstrap/';

$env: 'dev' !default;
$path-to-assets: '' !default;

@if($env == 'prod') {
  $path-to-assets: '/theme';
}

/*------------------------------------------------------------------
[ 1. Bootstrap Library ]
*/
@import 'helpers/fonts';

/*------------------------------------------------------------------
[ 2. Bootstrap Library ]
*/
@import '../libraries/bootstrap-sass/stylesheets/bootstrap';

/*------------------------------------------------------------------
[ 3. Variables ]
*/
@import 'helpers/variables';

/*------------------------------------------------------------------
[ 4. Buttons ]
*/
@import 'helpers/buttons';

/*------------------------------------------------------------------
[ 5. Common classes ]
*/
@import 'helpers/commons';

/*------------------------------------------------------------------
[ 6. Forms ]
*/
@import 'helpers/forms';

/*------------------------------------------------------------------
[ 7. Header ]
*/
@import 'helpers/header';

/*------------------------------------------------------------------
[ 8. Footer ]
*/
@import 'helpers/footer';

/*------------------------------------------------------------------
[ 9. Pagination ]
*/
@import 'helpers/pagination';

/*------------------------------------------------------------------
[ 10. Map ]
*/
@import 'helpers/map';

/*------------------------------------------------------------------
[ 11. Homepage Image ]
*/
@import 'helpers/hero_image';

/*------------------------------------------------------------------
[ 12. Social Buttons ]
*/
@import 'helpers/social';

/*------------------------------------------------------------------
[ 13. Listing Cards ]
*/
@import 'helpers/card';

/*------------------------------------------------------------------
[ 14. Filter ]
*/
@import 'helpers/filter';

/*------------------------------------------------------------------
[ 15. Listing Detail ]
*/
@import 'helpers/detail';


/*------------------------------------------------------------------
[ 16. Checkbox  ]
*/
@import 'helpers/checkbox';


/*------------------------------------------------------------------
[ 17. susbcription page  ]
*/
@import 'helpers/subscription';


/*------------------------------------------------------------------
[ 17. cookie alert RGPD  ]
*/
@import 'helpers/cookie';


/*------------------------------------------------------------------
[ 17. cookie alert RGPD  ]
*/
@import 'helpers/radiobox';


html {
    height: 100%;
}

body {
    background-color: #fff;
    color: $color-black-light;
    font-family: 'Viamaax';
    font-size: 1.6rem;
    height: 100%;
    overflow-x: hidden;
    width: 100%;

    &.listing-map {
        overflow-y: hidden;
    }
}

a {
    color: $color-supernova;
    transition: color .15s linear;

    &:hover {
        color: $color-supernova;
        text-decoration: underline;
    }
}

p {
    line-height: 24px;
}

h1, h2, h3 {
    font-weight: bold;
    margin-bottom: 30px;
}

h1 {
    font-size: 32px;

    @media (min-width: $screen-tablet) {
      font-size: 56px;
    }
}

h2 {
    font-size: 24px;

    @media (min-width: $screen-tablet) {
      font-size: 40px;
    }
}

h3 {
    font-size: 22px;
}

ol {
    li {
        margin-bottom: 15px;
    }
}

.page-wrapper {
    background-color: $color-gray;
    display: table;
    height: 100%;
    table-layout: fixed;
    width: 100%;
}

main,
aside {
    padding-bottom: 40px;

    @media (min-width: $screen-tablet) {
      padding-bottom: 80px;
    }
}

section.error {
  background-color: #fff;
  color: $color-black;
  margin-top: 40px;

  @media (min-width: $screen-tablet) {
    margin-top: 80px;
  }

  .error-container {
    padding: 30px;

    @media (min-width: $screen-tablet) {
      padding: 80px;
    }
  }

  p {
    color: $color-black;
    font-size: 20px;
    line-height: 23px;
  }

  a {
    color: $color-black;
    font-size: 16px;
    line-height: 19px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.phone-number {
  p {
    font-style: italic;
    font-size: 12px;
  }
}