.hero-image-inner {
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    height: 325px;
    justify-content: center;
    position: relative;

    &:after {
        background-color: rgba(black, .4);
        bottom: 0px;
        content: '';
        display: block;
        left: 0px;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    &.homepage {
      background-image: url('../img/security-controller-small.jpg');

      @media (min-width: $screen-tablet) {
        background-image: url('../img/security-controller-big.jpg');
        height: 600px;
      }
    }
}

.hero-image-content {
    text-align: center;
    z-index: 10;

    h1 {
        color: #fff;
        font-weight: bold;
        margin: 0px 0px 30px 0px;
    }

    p {
        color: #fff;
        font-size: 16px;
        margin: 0;

        @media (min-width: $screen-tablet) {
          font-size: 24px;
        }
    }

    .btn {
        margin-right: 20px;
    }

    @media (max-width: $screen-xs-max) {
      margin: 0 auto;
      width: 90%;
    }
}
