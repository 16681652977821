.center {
    text-align: center;
}

.right {
    text-align: right;
}

.block {
    padding-bottom: 40px;
    padding-top: 40px;

    @media (min-width: $screen-tablet) {
      padding-bottom: 80px;
      padding-top: 80px;
    }

    .page-header:first-of-type {
        margin-top: 0px;
    }
}

.align-right-tab-desk {
  @media (min-width: $screen-tablet) {
    display: inline-block;
    float: right;

    &::after {
      clear: both;
    }
  }
}

.background-white {
    background-color: #fff;
}

.background-black-light {
    background-color: $color-black-light;
    color: #fff;
}

.m0 {
    margin: 0px !important;
}

.mb0 {
    margin-bottom: 0px;
}

.mt0 {
    margin-top: 0px !important;
}

.mt-sm-10 {
  @media (max-width: $screen-tablet) {
    margin-top: 10px !important;
  }
}

.ml10 {
  margin-left: 10px;
}

.mb-15 {
  margin-bottom: -15px !important;
}

.mb30 {
    margin-bottom: 30px;
}
.mt30 {
    margin-top: 30px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb-80 {
    margin-bottom: -80px;
}

.mt80 {
    margin-top: 80px;
}

.mt-70 {
    margin-top: -70px;
}

.mt-80 {
    margin-top: -80px !important;
}

.mb80 {
    margin-bottom: 80px;
}

.mb-50 {
    margin-bottom: -50px;
}

.mt-140 {
    margin-top: -150px;
}

.mt-150 {
    margin-top: -150px;
}

.mt50 {
    margin-top: 50px;
}

.p30 {
    padding: 30px;
}

.p15 {
    padding: 15px;
}

.p20 {
    padding: 20px;
}

.pb0 {
    padding-bottom: 0px !important;
}

.pt0 {
    padding-top: 0px !important;
}

.pb60 {
    padding-bottom: 60px !important;
}

.pt60 {
    padding-top: 60px !important;
}

.page-header {
    border: 0px;
    margin: 40px 0px;
    padding: 0px;
    text-align: center;

    @media (min-width: $screen-tablet) {
      margin: 80px 0px;
    }

    h2 {
        color: $color-black;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin: 30px 0px 0px 0px;
        padding: 0px;

        a {
            border-bottom: 1px solid $color-supernova;

            &:hover {
                border-color: darken($color-supernova, 5%);
            }
        }
    }

    &.left {
        text-align: left;
    }
}

.box {
    @media (max-width: $screen-xs-max) {
        margin-bottom: 30px;
    }
}

.box-icon {
    float: left;
    height: 72px;
    margin: 0px 20px 0px 0px;
    position: relative;
    width: 72px;

    & > svg {
      height: 56px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform:translate(-50%, -50%);
      width: 56px;

      path {
        fill: $color-supernova;
      }
    }

  @media (min-width: $screen-sm) and (max-width: $screen-md) {
    float: none;
  }
}

.box-content {
    display: table-cell;

    .title {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        margin: 25px 0px;
    }

    p {
        margin: 0px;
    }

    a {
        color: $color-supernova;
        display: block;
        margin: 15px 0px 0px 0px;
    }

    .fa {
        font-size: 10px;
        margin: 0px 0px 0px 10px;
    }
}

.alert {
    border-radius: 0px;
    color: #fff;
    line-height: 2;
    padding: 20px 30px;

    &.alert-danger {
        background-color: $color-danger;
        border-color: darken($color-danger, 5%);
    }

    &.alert-warning {
        background-color: $color-warning;
        border-color: darken($color-warning, 5%);
    }

    &.alert-success {
        background-color: $color-success;
        border-color: darken($color-success, 5%);
    }

    &.alert-info {
        background-color: $color-info;
        border-color: darken($color-info, 5%);
    }

    .close {
        line-height: 1.5;
    }

    &:hover {
        .close {
            opacity: .5;
        }
    }
}

.alert-icon {
    &:before {
        display: inline-block;
        font-family: FontAwesome;
        font-size: 24px;
        -webkit-font-smoothing: antialiased;
        line-height: 0;
        margin-right: 10px;
        vertical-align: -3px;
    }

    &.alert-danger:before {
        content: "\f057";
    }

    &.alert-warning:before {
        content: "\f071";
    }

    &.alert-success:before {
        content: "\f164";
    }

    &.alert-info:before {
        content: "\f05a";
    }
}

.label {
    border-radius: 0px;
}

.label-md {
    font-size: 90%;
}

.label-lg {
    font-size: 110%;
}

.label-primary {
    background-color: $color-primary;
}

.label-secondary {
    background-color: $color-secondary;
}

.label-danger {
    background-color: $color-danger;
}

.label-warning {
    background-color: $color-warning;
}

.label-success {
    background-color: $color-success;
}

.label-info {
    background-color: $color-info;
}

.badge {
    background-color: #fff;

    .btn.btn-primary & {
        color: $color-primary;
    }

    .btn.btn-secondary & {
        color: $color-secondary;
    }
}

.document-title {
    background-repeat: no-repeat;
    background-image: url(#{$path-to-assets}/img/staff-small.jpg);
    background-position: 70% center;
    margin: 0 auto 40px;
    padding: 127px 0;
    position: relative;

    @media (min-width: $screen-tablet) {
      background-image: url(#{$path-to-assets}/img/staff-big.jpg);
      background-position: center;
      margin-bottom: 80px;
      padding: 269px 0;
    }

    h1 {
        color: #fff;
        margin: 0px;
        padding: 0px;
        position: relative;
        text-align: center;
        z-index: 2;
    }

    &:after {
        background-color: rgba(black, .4);
        bottom: 0px;
        content: '';
        display: block;
        left: 0px;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
    }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.results-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: $screen-phone) {
    justify-content: space-between;
  }

  h2 {
    padding: 0;
    margin: 20px 0;
    color: $color-black-light;
  }

  .dropdown-toggle {
    font-size: 15px;

    @media (min-width: $screen-tablet) {
      font-size: 16px;;
    }
  }
}

.back-arrow::before{
  content: '<';
  left: -3px;
  position: absolute;
}
