header.main {
    background-color: #fff;
    position: relative;
    z-index: 200;

    .dropdown:hover .dropdown-menu {
      @media(min-width: $screen-tablet) {
        display: block;
      }
    }

    .navbar {
      margin-bottom: 0;
      min-height: 100px;
    }

    .navbar-community {
      @extend .navbar-default;
      background-color: white;

      .container-fluid {
        max-width: 1170px;
        padding-top: 20px;

        .navbar-brand {
          margin-left: 0;
          padding: 0 15px 0 0;
        }

        .navbar-header {
          @media(max-width: $screen-tablet) {
            margin-left: 0;
          }
        }
      }

      .navbar-toggle {
        border-color: $color-black-light;
        border-radius: 0;
        margin: 12px 15px 12px 0;

        .icon-bar {
          background-color: $color-black-light;
        }
      }

      .navbar-collapse {
        border: none;
        padding-right: 0;

        &.in {
          overflow-y: inherit;
        }
      }

      .navbar-nav {

        @media(max-width: $screen-tablet) {
          margin-left: 0;
        }

        & > li {
          &:hover {
            & > a {
              background-color: $color-black-light;
              color: white;
            }
          }

          & > a {
            color: $color-black-light;
            line-height: 16px;
            padding: 20px 12px;
            transition: none;

            &:focus {
              background-color: $color-black-light;
              color: white;
            }
          }
        }

        .dropdown.active {
          &:hover {
            & > a {
              background-color: $color-black-light;
              color: white;
            }
          }

          & > a {
            background-color: white;
            color: $color-black-light;
            font-weight: bold;

            &:hover,
            &:focus {
              background-color: $color-black-light;
              color: white;
            }
          }
        }

        .dropdown.open {
          & > a {
            background-color: $color-black-light;
            color: white;
          }
        }

        .dropdown-menu {
          background-color: $color-black-light;
          border: 0;
          border-radius: 0;
          font-size: 14px;
          min-width: 290px;
          padding: 0;

          & > li  > a {
            border-bottom: 1px solid $color-black;
            color: white !important;
            padding: 15px 40px 15px 20px;

            @media(min-width: $screen-tablet) {
              padding: 10px 40px 10px 20px;
            }

            &.current {
              font-weight: bold;
            }

            &:hover,
            &:focus {
              background-color: $color-black;
              color: $color-supernova !important;
            }
          }

          & > li:last-child > a {
            border-bottom: none;
          }
        }
      }

      .dropdown-toggle {
        background-image: url('../img/arrow-down-black.png');
        background-repeat: no-repeat;
        background-position: 90% center;

        @media(min-width: $screen-tablet) {
          background-position: center 85%;
        }
      }
    }

    .navbar-text {
      color: $color-black-light !important;
      font-size: 16px;
      font-weight: bold;

      @media(min-width: $screen-phone) {
        line-height: 56px;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
}
