.footer {

  font-family: 'Viamaax';
  font-weight: 600;

  .footer-top {

    background-color: #393939;
    color: #fff;
    font-size: 13px;

    @media (min-width: $screen-xs-max) {
      padding: 40px 0 30px 0;
    }

    h4 {
      color: #fff;
      font-size: 24px;
      margin-bottom: 5px !important;
      font-weight: 600;
    }

    p {
      margin: 0 0 30px;
    }

    .mailto {
      color: #fff;
      font-size: 20px;
      text-decoration: underline;
    }

    .nav.social-links {
      > li {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    @media (max-width: $screen-xs-max) {
      .col-sm-4 {
        margin: 50px 0;
      }
    }
  }

  .footer-bottom {
    @extend .clearfix;
    background-color: $color-black-light;
    color: #fff;
    font-size: 13px;
    padding: 35px 0;
  }

  .footer-bottom-center {
    text-align: center;
  }
}
